


























































import {
  Vue,
  Component,
  PropSync,
  Ref,
  Watch,
  Prop
} from "vue-property-decorator";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { CertificateTypeModel } from "@/api/generated";
import CrewMemberCertificateCreateModel from "@/models/crewMemberCertificateCreateModel";
import { apiClient } from "@/services/apiService";
import FormDataFactory from "@/utility/formDataFactory";
import Validation from "@/mixins/validation";
import { ContractType } from '../../api/generated/api';
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CrewMemberCertificateCreateDialog extends Validation {
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;
  @Prop(String) private crewMemberId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private certificateTypes: Array<CertificateTypeModel> = [];
  private model: CrewMemberCertificateCreateModel = {
    crewMemberId: "",
    certificateTypeId: "",
    certificate: null,
    reference: "",
    filename: "",
    expiryDate: null,
    discriminator: getContractType(this.discriminator)
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
    await this.getCertificateTypes();
  }

  private async created() {
    await this.getCertificateTypes();
  }

  private async getCertificateTypes() {
    try {
      const response = await Api.CertificateTypeService.apiCertificatetypeDiscriminatorGet(this.model.discriminator);
      this.certificateTypes = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load certificate types");
      this.syncedDialog = false;
    }
  }

  private async onCreateCertificate() {
    if (!this.form.validate()) {
      return;
    }

    this.model.crewMemberId = this.crewMemberId;
    this.model.filename = this.model.certificate?.name ?? "";

    try {
      this.loading = true;
      const formData = FormDataFactory.Create(this.model);
      await apiClient.post("api/crewMemberCertificate", formData);
      snackbarModule.setSnackbarMessage("Crew member certificate created");
      this.$emit("certificate-created");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to create crew member certificate"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
