
















































import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { CrewMemberCertificateUpdateModel } from "@/api/generated";
import momentUtility from "@/utility/momentUtility";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CrewMemberCertificateUpdateDialog extends Validation {
  @Prop(String) private crewMemberCertificateId!: string;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: CrewMemberCertificateUpdateModel = {
    reference: "",
    filename: "",
    expiryDate: "",
    startDate: ""
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await Api.CrewMemberCertificateService.apiCrewmembercertificateCrewMemberCertificateIdGet(
        this.crewMemberCertificateId
      );
      this.model = response.data;

      if (this.model.startDate) {
        this.model.startDate = momentUtility.pickerFormat(this.model.startDate);
      }

      if (this.model.expiryDate) {
        this.model.expiryDate = momentUtility.pickerFormat(
          this.model.expiryDate
        );
      }
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to fetch crew member certificate"
      );
      this.syncedDialog = false;
    }
  }

  private async onCertificateUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      await Api.CrewMemberCertificateService.apiCrewmembercertificateCrewMemberCertificateIdPut(
        this.crewMemberCertificateId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Certificate updated");
      this.$emit("certificate-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update certificate");
    } finally {
      this.syncedDialog = false;
    }
  }
}
